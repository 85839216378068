import $ from 'jquery';

class RemoteForms {
  constructor() {
    this.setupUserForm();
  }

  setupUserForm() {
    $('._user-form').on('submit', (e) => {
      if (!grecaptcha.getResponse()) {
        this.error('es' == ewtn.lang ? 'Es necesario completar el reCAPTCHA' : 'The reCAPTCHA is required');
        return false;
      }
      $('._user-form .form').hide();
    }).on('turbo:submit-end', (e) => {
      let data = e.detail;
      if (data.success) {
        $('._user-form .success').show();
        $('._user-form .error').hide();
      } else {
        this.error('es' == ewtn.lang ? 'Ocurrió un error' : 'An error occurred', 'system')
      }
      grecaptcha.reset();
    });
    $('._user-form .resubmit').on('click', (e) => {
      $('._user-form .result').hide();
      $('._user-form .form').show();
    });
  }

  error(message, system) {
    $('._user-form .error-message').text(message);
    if (system)
      $('._user-form .error-report').show();
    else
      $('._user-form .error-report').hide();
    $('._user-form .error').show();
  }
}

export default RemoteForms;
