import $ from 'jquery';

class Selector {
  refreshControls($selector, item) {
    if ($selector.data('sel-type') == 'scroll') {
      let values = $selector.data('sel-values').split(',');
      $selector.find('[data-sel-control]').removeClass('hidden');
      if (item == values[0]) $selector.find('[data-sel-control="prev"]').addClass('hidden');
      else if (item == values[values.length - 1]) $selector.find('[data-sel-control="next"]').addClass('hidden');
    } else {
      $selector.find('[data-sel-control].active').removeClass('active');
      $selector.find('[data-sel-control][data-sel-item="' + item + '"]').addClass('active');
    }
  }

  constructor() {
    if (!(window.navigator.maxTouchPoints || 'ontouchstart' in document))
      $('[data-sel-type="dropdown"]').hover((e) => $(e.currentTarget).addClass('hovering'), (e) => $(e.currentTarget).removeClass('hovering'));

    $('[data-sel-control]').on('click', (e) => {
      e.stopPropagation();
      let $control = $(e.currentTarget),
        $selector = $control.closest('[data-sel-type]'),
        selectorClass = $selector.data('sel-class'),
        selectorType = $selector.data('sel-type'),
        item = $control.data('sel-item');
      if ('dropdown' == selectorType) {
        if ($selector.hasClass('hovering')) {
          $selector.removeClass('hovering'); // desktop: drop-down open, close it and process selection
        } else {
          $selector.addClass('hovering'); // mobile: drop-down closed, open it
          return;
        }
      }
      if ($control.hasClass('active')) return;
      if ($selector.data('sel-url')) {
        this.refreshControls($selector, item);
        window.location = $selector.data('sel-url') + '/' + item;
      } else {
        if ('scroll' == selectorType) {
          let values = $selector.data('sel-values').split(','),
              currentItem = $('[data-sel-item].current').eq(0).data('sel-item').toString(),
              currentIndex = values.indexOf(currentItem),
              nextIndex = currentIndex + ($control.data('sel-control') == 'prev' ? -1 : 1);
          $('[data-sel-item]').removeClass('current');
          item = values[nextIndex];
          $('[data-sel-item="' + item + '"]').addClass('current');
        } else {
          $('[data-sel-item]').removeClass('current');
          let selected = '__all__' == item ? '[data-sel-item]' : '[data-sel-item="' + item + '"]';
          $(selected).addClass('current');
        }
        $('[data-sel-class="' + selectorClass + '"]').each((i, sel) => this.refreshControls($(sel), item));
      }
    });
  }

}

export default Selector;
