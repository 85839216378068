import $ from 'jquery';

class ChannelFinder {

  constructor() {
    $("#frm1").submit(() => this.getChannels());
    $("#zccCountry").change(() => this.clearZip());
    this.msgs = {
      en: {
        channel: 'Channel',
        error: 'Unexpected error retrieving channel data',
        badzip: 'No information was found for postal code',
        retry: 'Please check the code and try again'
      },
      es: {
        channel: 'Canal',
        error: 'Recibimos un error al buscar los datos',
        badzip: 'No encontramos ninguna información para el código postal',
        retry: 'Favor de corregir el código e intentar de nuevo'
      }
    };
  }

  getChannels() {
    var country = $("#zccCountry").val();
    var zip = $("#zccZip").val();

    this.disableSubmit();
    this.processChannels(country, zip);
    return false;
  }

  processChannels(country, zip) {
    $.ajax({
      url: `https://channel-finder.ewtnos.com/cf/${country}/${zip}`,
      dataType: 'json'
    })
    .done((data) => this.channelSuccess(data))
    .always(() => this.enableSubmit())
    .fail(() => this.channelFail());
  }

  channelFail(response) {
    var msg = `${this.msgs[ewtn.lang].error}: [${response.status}] ${response.statusText}`;
    this.displayError(msg);
  }

  channelSuccess(data) {
    var name = $("#zccCountry option:selected").text();
    var zip = $("#zccZip").val();

    $("#channelfinder").html("");
    this.clearError();

    if (data.ERROR) {
      var msg = `${this.msgs[ewtn.lang].badzip} ${zip}.  ${this.msgs[ewtn.lang].retry}.`;
      this.displayError(msg);
    } else {
      var ziptext = name + ' - ' + zip;
      var $div_head = $('<div>').addClass('zcc-head').html(ziptext);
      $('#channelfinder').append($div_head);
      this.getProviderInfo(data);
    }
  }

  getProviderInfo(data) {
    var $channelContainer = $('#channelfinder');

    for (var t = 0; t < data.length; t++) {
      if (data[t].providers.length == 0) continue;

      var prov = data[t].type;
      var $h2_provider_type = $('<h2>').addClass('zcc-provider-type').text(prov);
      $channelContainer.append($h2_provider_type);
      var $div_provider = $('<div>').addClass('zcc-provider');

      for (var p = 0; p < data[t].providers.length; p++) {
        var provider_name = data[t].providers[p].name;
        var $span_provider_name = $('<span>').addClass('zcc-provider-name').html(provider_name);

        var $span_provider_info = $('<span>').addClass('zcc-providers-info');
        $span_provider_info.append($span_provider_name);

        var $div_providers = $('<div>').addClass('zcc-providers');
        $div_providers.append($span_provider_info);

        // Provider's Station Name
        for (var s = 0; s < data[t].providers[p].stations.length; s++) {
          var station_name  = data[t].providers[p].stations[s].name;
          var $span_station = $('<span>').addClass('zcc-station').html(station_name);

          var $span_station_channel = $('<span>').addClass('zcc-station-channel');
          $span_station_channel.append($span_station);

          $span_station_channel.append(
            $('<span>').addClass('zcc-channel').html(`${this.msgs[ewtn.lang].channel} ${data[t].providers[p].stations[s].channels.join(' / ')}`)
          );

          var $div_station_channel_list = $('<div>').addClass('zcc-station-channel-list');
          $div_station_channel_list.append($span_station_channel);

          $div_providers.append($div_station_channel_list);
        }

        $div_provider.append($div_providers);
        $channelContainer.append($div_provider);
      }
    }
  }

  enableSubmit() {
    $("#zccSubmit").show();
    $(".zcc-loading").hide();
  }

  disableSubmit() {
    $("#zccSubmit").hide();
    $(".zcc-loading").show();
    this.clearError();
  }

  clearZip() {
    $("#zccZip").val('');
    this.clearError();
  }

  clearError() {
    $("#errorContainer").html("");
  }

  displayError(msg) {
    var $error = $('<div>').attr('id', 'zccFormText').addClass('zcc-error').html(msg);
    $('#errorContainer').append($error);
  }

}

export default ChannelFinder;
