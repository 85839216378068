// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

import DateUtil from './src/date-util'
import DomUtil from './src/dom-util'
import Schedules from './src/schedules'

window.du = new DateUtil();
window.dom = new DomUtil();

import ScheduleElements from './src/schedule-elements'
import Selector from './src/selector'
import ChannelFinder from './src/channel-finder'
import RemoteForms from './src/remote-forms'
import Readings from './src/readings'
import Modal from './src/modal'
import Visibility from './src/visibility'
import OurBooks from './src/our-books'

document.addEventListener('turbo:load', function() {
  window.ewtn = { lang: $('html').attr('lang'), foo: 'bar', ack: 'pht' };
  dataLayer.push({ event: 'pageView', virtualUrl: window.location.href }); // so GTM tags can fire on link navigation

  new ScheduleElements();
  new Selector();
  new ChannelFinder();
  new RemoteForms();
  new Readings();
  new Schedules();
  new Modal();
  new Visibility();
  new OurBooks();

  $('.hamburger').on('click', function(e) {
    e.stopPropagation();
    $(this).toggleClass('hamburger__change');
    if ($(this).hasClass('hamburger__change'))
      $('.mega-menu').slideDown();
    else
      $('.mega-menu').slideUp();
  });

  // undocumented fix for ShareThis turbolinks incompatibility: https://github.com/reed/turbolinks-compatibility/issues/79
  if (typeof __sharethis__ != 'undefined' && __sharethis__.config) {
    __sharethis__.href = document.location.href;
    __sharethis__.init(__sharethis__.config);
  }

  let $hs = $('[data-hubspot-form-id]');
  if ($hs.length > 0) {
    $hs.find('iframe').remove();
    hbspt.forms.create({
      portalId: "4395239",
      formId: $hs.data('hubspot-form-id'),
      target: '#hs-form'
    });
  }
  // Marin - RaiseDonors tracking
  let $div = $('#rd-marin-tracker');
  if (new URLSearchParams(window.location.search).has('mkwid') && $('#raiseDonorsEmbedForm').length < 1) {
    $div.html($div.find('script').html().replace('{params}', window.location.search.substring(1)));
  } else {
    $div.html('');
  }
});

// turbolinks fix for Google reCAPTCHA (https://github.com/turbolinks/turbolinks/issues/253#issuecomment-289101048)
document.addEventListener('turbo:before-cache', () => {
  $('.g-recaptcha').empty();
});
