import $ from 'jquery'

class OurBooks {

  navigate() {
    let $sort = this.$form.find('[name=sort]'),
        $featured = this.$form.find('[name=featured]'),
        $cats = [], $authors = [], $languages = [];
    this.$form.find('input._cat').each((i, it) => {
      let $it = $(it); if ($it.prop('checked')) $cats.push($it.attr('name'));
    });
    this.$form.find('input._author').each((i, it) => {
      let $it = $(it); if ($it.prop('checked')) $authors.push($it.attr('name'));
    });
    this.$form.find('input._language').each((i, it) => {
      let $it = $(it); if ($it.prop('checked')) $languages.push($it.attr('name'));
    });
    Turbo.visit(`/publishing/ourbooks?sort=${$sort.val()}&featured=${$featured.prop('checked')}&cats=${$cats.join(',')}&authors=${$authors.join(',')}&langs=${$languages.join(',')}`);
  }

  constructor() {
    this.$form = $('#ourbooks');
    if (this.$form.length < 1) return;
    this.$form.find('input,select').on('change', () => this.navigate());
  }

}

export default OurBooks;
