import $ from 'jquery'

class Visibility {

  constructor() {
    let $container = $('._visibility');
    if ($container.length < 1) return;
    $container.find('._toggle-visibility').on('click', () => this.toggleVisibility());
    this.$items = $container.find('._visibility-item');
  }

  toggleVisibility() {
    this.$items.each((i, it) => {
      let $it = $(it);
      if ($it.hasClass('--invisible'))
        $it.removeClass('--invisible');
      else
        $it.addClass('--invisible');
    });
  }
}

export default Visibility;
