class Readings {
  showTab(tabId, tabNo) {
    $('div[data-tab-id="' + tabId + '"]', '#readings__wrapper').removeClass('current').each((i, div) => {
      if ($(div).data('tab-no') == tabNo) $(div).addClass('current');
    });
  }

  changeTab(e) {
    e.stopPropagation();
    var $target = $(e.target),
        tabNo = $target.data('tab-no'),
        tabId = $target.data('tab-id');
    this.showTab(tabId, tabNo);
  }

  initMagTvVideoLink() {
    $.ajax({
      url: 'https://www.magnificat.tv/rest/misadeldia.jsonp',
      dataType: 'jsonp',
      success: (data) => $('#magtv').attr('data-media-id', data[0].Video.file.uri.replace('youtube://v/', ''))
    })
  }

  handleDatePick(dateText, inst) {
    var m = window.location.pathname.match(/(.*)\/[0-9\-]+/),
        location = m ? m[1] : window.location.pathname;
    window.location = location + '/' + dateText;
  }

  constructor() {
    if ($('#readings__wrapper').length < 1) return;
    $('#readings__wrapper').on('click', 'div.readings__tab[data-tab-id]', (e) => this.changeTab(e));
    if ('es' == ewtn.lang) this.initMagTvVideoLink();
  }

}

export default Readings;
