import $ from 'jquery'

class Schedules {
  constructor() {
    let today = new Date();

    $('.schedule__entry').each((i, e) => {
      let $entry = $(e),
          $time = $entry.find('._time'),
          startDate = new Date($time.data('datetime')),
          endDate = new Date($time.data('endtime'));
      $time.text(du.civilian(startDate.getHours()) + ':' + du.fill2(startDate.getMinutes()) + ' ' + du.amPm(startDate.getHours()));
      $entry.attr('data-sel-item', '_' + (startDate.getMonth() + 1) + '-' + startDate.getDate());
      if (startDate <= today && today < endDate) {
        $time.parent().next().addClass('active');
      }
    });

    let $activeDay = $('[data-sel-class="day"] [data-sel-control].active');
    if ($activeDay.length > 0) {
      $('[data-sel-item="' + $activeDay.data('sel-item') + '"]').addClass('current');
    } else {
      let dayClass = '_' + (today.getMonth() + 1) + '-' + today.getDate();
      $('[data-sel-item="' + dayClass + '"]').addClass('current');
      $('[data-sel-control][data-sel-item="' + dayClass + '"]').addClass('active');
    }

    $('.schedule__link').on('click', (e) => {
      e.stopPropagation();
      if ($(e.target).is('a')) return; // don't close entry if user clicked on link inside entry
      let $target = $(e.currentTarget).closest('.schedule__entry'),
          close = $target.hasClass('active');
      $('.schedule__entry.active').removeClass('active');
      if (!close) $target.addClass('active');
    });

    let $specials = $('.schedule__container.--theme--special');
    if ($specials.length > 0) {
      let dayDivider = $specials.find('#day-divider').html(),
          lang = ewtn.lang,
          prevTime, dups;
      $specials.find('.schedule__entry').each(function(i, se) {
        let $entry = $(se),
            startTime = new Date($entry.find('.schedule__time').data('datetime'));
        if (!prevTime || startTime.getDate() != prevTime.getDate()) {
          $entry.before($(replaceAll(dayDivider, {
            weekday: du.dayNames[lang][startTime.getDay()].slice(0,3),
            date: du.formatDate(startTime, 'widget', lang)
          })));
          prevTime = startTime;
          dups = {};
        }
        if (dups[$entry.data('title-code')]) $entry.hide();
        dups[$entry.data('title-code')] = true;
      });
    }
  }

}

export default Schedules;
