import $ from 'jquery'

// Site-wide programming schedule-related elements
class ScheduleElements {
  nowShowing() {
    let $panel = $('._now-showing');
    if ($panel.length < 1) return;
    $.ajax({
      url: `/api/now-showing/${$panel.data('sat')}`,
      dataType: 'json',
      success: function(data) {
        let $titles = $panel.find('._title'),
            $times = $panel.find('._time'),
            now = new Date(), title, start, j = 0;
        while (j < data.length && now >= new Date(data[j].end_date)) j++;
        for (let i = 0; i < $titles.length && j < data.length; i++, j++) {
          $titles.eq(i).text(data[j].program_name);
          let start = new Date(data[j].start_date);
          $times.eq(i).text(`${du.civilian(start.getHours())}:${du.fill2(start.getMinutes())} ${du.amPm(start.getHours())}`);
        }
      }
    });
  }

  nowPlaying() {
    let $panel = $('._now-playing');
    if ($panel.length < 1) return;
    $.ajax({
      url: `//www.ewtn.com/Live/ewtnplayer/scheds/${$panel.data('sat')}.json`,
      dataType: 'json',
      success: function(data) {
        let $titles = $panel.find('._title'),
            $times = $panel.find('._time'),
            now = new Date(), i = 0;
        while (i < data.length) {
          let endDate = new Date(data[i].end_date);
          if (endDate > now) break;
          i++;
        }
        for (let j = 0; j < 2 && i < data.length; j++, i++) {
          $titles.eq(j).text(data[i].program_name);
          let date = new Date(data[i].start_date);
          $times.eq(j).text(`${du.civilian(date.getHours())}:${du.fill2(date.getMinutes())} ${du.amPm(date.getHours())}`);
        }
      }
    });
  }

  constructor() {
    $('._time').each(function(i, span) {
      let $span = $(span);
      $span.text(du.formatTime(new Date($span.data('datetime'))));
    });
    $('._datetime').each(function(i, span) {
      let $span = $(span);
      $span.text(du.formatDateTime(new Date($span.data('datetime')), 'informal', ewtn.lang));
    });
    $('._weektime').each(function(i, span) {
      let $span = $(span);
      $span.text(du.formatWeekTime(new Date($span.data('datetime')), ewtn.lang));
    });
    $('._yeardatetime').each(function(i, span) {
      let $span = $(span);
      $span.text(du.formatDateTime(new Date($span.data('datetime')), 'simple', ewtn.lang));
    });
    $('._weekdays').each(function(i, span) {
      let $span = $(span);
      $span.text(du.formatWeekDay(new Date($span.data('datetime')), 'plural', ewtn.lang));
    });
    this.nowShowing();
    this.nowPlaying();
  }
}

export default ScheduleElements;
